@import "../../../../../partials/variables";
@import "../../../../../partials/mixins";

.UserData_Result_NumberColorSets {
  .title2 {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    h2 {
      text-align: center;
      color: white;
    }
    .number {
      color: $dmaps-darkBlue;
    }
  }

  .allUserColorSet {
    border: 2px solid $border-color;
    border-radius: 10px;
    background-color: white;

    .titles {
        @include titles_line;
        margin-top: 0;
        margin-bottom: 10px;

        .number_colorSet,
        .date_create,
        .actual_state{
          text-align: center;
        }
    }

    .number_colorSet{
      width: 140px;
    }
    .company {
      flex: 1;
      padding-left: 10px;
    }
    .date_create{
      width: 120px;
    }
    .actual_state{
      width: 120px;
    }


    .oneColorSet {
      display: flex;
      transition: background-color 0.1s linear;
      padding: 5px;
      border-bottom: 1px solid rgb(204, 204, 204);
      cursor: pointer;
      margin: 5px;
      gap: 10px;
      
      *{
        cursor: pointer;
      }

      &:hover {
        background-color: $oneMapSelect-hover-color;
        border-radius: 10px;
      }

      .number_colorSet,
      .date_create,
      .actual_state{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .pageScroll{
    position: relative;
    background-color: white;
    width: 100%;
    left: -10px;
    border-radius: 10px;
  }
}

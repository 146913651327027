@import "./partials/variables";

.button_blue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: lighten($dmaps-darkBlue, 5%);
  border-radius: 20px;
  padding: 8px 22px;
  font-size: 20px;
  color: whitesmoke;
  transition: scale 0.1s linear, background-color 0.2s linear;

  p {
    transition: opacity 0.3s linear, visibility 0.3s linear;
    white-space: nowrap;
  }

  svg {
    font-size: 36px;
    transition: color 0.3s linear, transform 0.3s linear;
  }
}

@import "./../../../../partials/variables";

.Btn_Back{
    position: absolute;
    left: 4%;
    top: calc(50% - 5px);
    transform: translateY(-50%);

    button{
        font-size: 40px;
        color: $dmaps-darkBlue;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: margin-left 0.1s linear;

        &:hover{
            margin-left: 0;
        }
    }
    
}
@import "../../../../partials/variables";
@import "../../../../partials/mixins";

.NavUserData {
  background-color: $menu-color;
  // background-color: rgba(1,95,129,255);
  //   background: linear-gradient(
  //   to bottom,
  //   rgba(0,46,72,1),
  //   rgb(2, 111, 151)
  // );
  padding: 0 0px 10px 10px;
  height: $mainPage-height;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-right-radius: 30px;
  margin: 5px 0;
  border-bottom-right-radius: 30px;

  .NavUserData_main {
    .admin_change {
      background-color: rgb(8, 8, 8);
      padding: 2px;
      border-radius: 30px;
      display: flex;
      justify-content: space-evenly;
      margin-right: 10px;
      margin-top: 20px;

      .normalButton {
        border-radius: 30px;
        &:hover {
          transform: scale(1.2);
        }

        .hover_checkout {
          display: flex;
          align-items: center;

          .hover_text {
            font-size: 20px;
            opacity: 0; // Text bude standardně skrytý
            overflow: hidden; // Skryje obsah, který přesahuje
            white-space: nowrap; // Zabrání zalamování textu
            width: 0px; // Výchozí šířka je 0
            transition: width 0.7s ease, opacity 0.3s ease 0.4s,
              padding-left 0.4s ease; // Postupné roztahování a zpožděné zobrazení opacity
          }
        }

        &:hover .hover_text {
          width: 100%; // Postupné roztažení do plné šířky
          opacity: 1; // Zobrazení textu po 0.4 sekundách
          padding-left: 10px;
        }
      }

      svg {
        width: 25px;
        height: 20px;
      }
    }

    .newRespondent {
      width: 100%;
      padding-bottom: 15px;
      padding-right: 10px;
      margin-bottom: 5px;
    }

    .nav-box {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 18px;
      width: 100%;
      // text-align: center;
      // height: 100%;

      a {
        // border-bottom: 1px solid $border-color;
        padding: 10px 0 10px 50px;
        color: white;
        width: 100%;
        transition: z-index 0.5s linear;
        font-size: 18px;
        position: relative;

        &:hover:not(.active) {
          background-color: rgb(252, 252, 252);
          // transform: scale(1.1);
          color: black;
          z-index: 3;
        }

        svg {
          font-size: 25px;
          position: absolute;
          left: 15px;
        }

        .company_icon {
          font-size: 30px;
          left: 10px;
        }
      }
      .active {
        background-color: rgb(255, 255, 255);
        color: black;
        position: relative;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        .colorBox {
          position: absolute;
          width: 30px;
          height: 20px;
          background-color: $menu-color;
          right: 0;
          z-index: 2;
        }

        .colorWhite {
          z-index: 1;
          width: 30px;
          height: 80px;
          position: absolute;
          right: 0;
          top: -20px;
          background-color: white;
        }
        .colorBox_top {
          top: -20px;
          border-bottom-right-radius: 50%;
        }
        .colorBox_bottom {
          bottom: -20px;
          border-top-right-radius: 50%;
        }
      }
    }
  }

  .NavUserData_bottom {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-right: 10px;

    .consultant {
      .btn_consultants {
        cursor: pointer;
        background-color: lighten($dmaps-darkBlue, 5%);
        border-radius: 20px;
        padding: 8px 22px;
        // border: 2px solid $dmaps-orange;
        color: white;
        width: 100%;
        font-size: 20px;
        transition: scale 0.1s linear, background-color 0.2s linear;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          transition: opacity 0.3s linear, visibility 0.3s linear;
        }

        svg {
          font-size: 36px;
          transition: color 0.3s linear, transform 0.3s linear;
        }

        &:hover {
          scale: 1.05;
          background-color: lighten($dmaps-darkBlue, 10%);

          p {
            opacity: 0;
            visibility: hidden;
          }

          svg {
            transform: translateX(-80px) scale(1.2);
            left: 30px;
            transition: color 0.3s linear, transform 0.3s linear;
          }
        }
      }
    }

    // .admin_details {
    //   text-align: center;
    //   border-radius: 20px;
    //   color: white;
    //   transition: scale 0.1s linear, color 0.2s linear,
    //     background-color 0.3s linear;
    //   cursor: pointer;
    //   padding: 5px 10px;
    //   background-color: lighten($dmaps-darkBlue, 5%);
    //   position: relative;
    //   display: flex;
    //   // align-items: center;
    //   // justify-content: space-evenly;

    //   &:hover {
    //     scale: 1.05;
    //     background-color: white;
    //     color: black;

    //     .admin_data {
    //       opacity: 0;
    //       visibility: hidden;
    //       transition: opacity 0.3s linear, visibility 0.3s linear;
    //     }

    //     // svg {
    //     //   color: black;
    //     //   transform: translateX(-80px) scale(1.2);
    //     //   left: 30px;
    //     //   transition: color 0.3s linear, transform 0.3s linear;
    //     // }
    //     svg {
    //       color: black;
    //       // position: static;
    //       left: 50%;
    //       transform: translate(-50%, -50%) scale(1.2);
    //       transition: color 0.3s linear, transform 0.3s linear;
    //     }
    //   }

    //   * {
    //     cursor: pointer;
    //   }

    //   .admin_data {
    //     text-align: left;
    //     transition: opacity 0.3s linear, visibility 0.3s linear;

    //     .email,
    //     .rank {
    //       font-size: 13px;
    //     }
    //   }

    //   svg {
    //     color: white;
    //     font-size: 40px;
    //     transition: color 0.3s linear, transform 0.3s linear;
    //     position: absolute;
    //     left:  calc(100% - (20px + 10px));
    //     top: 50%;
    //     transform: translate(-50%, -50%);
    //   }
    // }

    .admin_details {
      text-align: center;
      border-radius: 20px;
      color: white;
      transition: transform 0.1s linear, color 0.2s linear, background-color 0.3s linear;
      cursor: pointer;
      padding: 5px 10px;
      background-color: lighten($dmaps-darkBlue, 5%);
      position: relative;
      display: flex;
    
      &:hover {
        transform: scale(1.05);
        background-color: white;
        color: black;
    
        .admin_data {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s linear, visibility 0.3s linear;
        }
    
        svg {
          color: black;
          left: 50%;
          transform: translate(-50%, -50%) scale(1.2);
          transition: color 0.3s linear, transform 0.3s linear;
        }
      }
    
      * {
        cursor: pointer;
      }
    
      .admin_data {
        text-align: left;
        transition: opacity 0.3s linear, visibility 0.3s linear;
    
        .email,
        .rank {
          font-size: 13px;
        }
      }
    
      svg {
        color: white;
        font-size: 40px;
        transition: color 0.3s linear, transform 0.3s linear;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(200%, -50%);
      }
    }
    
  }
}

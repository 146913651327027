.Login_Form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn_forgot_password{
        font-size: 16px;
        color: rgb(3, 99, 209);
        transition: color 0.2s linear;

        &:hover {
            color: rgb(44, 137, 245);
        }
    }
}
@import "../../../../../../partials/variables";

.InputLine_Text_WithLabel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  
    .intput_box {
      position: relative;
      width: 100%;
  
      &:focus-within label {
        top: -13px;
        left: 10px;
        font-size: 15px;
      }
  
      input {
        width: 100%;
        min-width: 250px;
        padding: 8px 10px 7px 20px;
        outline: none;
        border: none;
        border-bottom: 2px solid lighten($dmaps-orange, 30%);
        color: #0f0f0f;
        cursor: text;
        font-size: 16px;
        background-color: $main-bgColor;
        transition: all 0.3s linear;
        // background-color: lighten(#cccccc, 30%);
    
        &:hover {
          border-color: lighten($dmaps-orange, 15%);
          background-color: lighten(#cccccc, 15%);
        }
        &:focus {
          border-color: $dmaps-orange;
          background-color: #cccccc;
        }
      }
  
      label {
        cursor: text;
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        color: #929191;
        background-color: transparent;
        padding: 0 5px;
        transition: all 0.2s ease-in-out;
      }
    }
  }
@import "../../../../../../partials/variables";

.RespondentNew0_respondent_box{
    display: flex;
    flex-direction: column;
    // gap: 30px;
    padding-top: 30px;

    h2 {
        text-align: center;
        font-size: 20px;
        font-weight: normal;
        color: $dmaps-darkBlue;
        padding: 10px;
        margin: 0 10px;
        border-bottom: 3px solid $dmaps-darkBlue;
    }

    .gender_box{
        margin-bottom: 15px;
    }

    .tag_profession {
        // padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 28px;
        background-color: white;
        border: 3px solid $dmaps-darkBlue;
        padding: 28px 20px 20px 20px;
        border-radius: 20px;
    }

    .email_form {
        background-color: white;
        border-radius: 20px;
        margin-top: 10px;
    }
}
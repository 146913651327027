@import "../../../../../../../partials/variables";

.NewCompany {
  .btn_back {
    position: absolute;
    width: 30px;
    height: 40px;
    left: 25px;
    top: 15px;
    transition: color 0.3s linear;
    z-index: 500;

    &:hover {
      color: rgb(94, 94, 94);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .NewCompany_main {
    margin: 10px 0;
    background-color: $menu-color;
    padding: 10px;
    box-shadow: 0 0 10px black;
    border-radius: 20px;
    flex-grow: 1;

    .company_info{
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .btn_add{
      margin: 20px 0;
      display: flex;
      justify-content: space-evenly;
      padding: 10px 0;
      background-color: rgb(160, 160, 160);
      border-radius: 10px;

      button {
        padding: 5px 20px;
      }
    }

    .CEO,
    .main_processor {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      // border-bottom: 2px solid black;
      gap: 20px;
      margin-bottom: 20px;
    }

    .newCompanny_error {
      min-height: 21px;
      text-align: center;
      color: red;
    }

    .btn_create{
      display: flex;
      justify-content: center;
      padding: 24px 0 20px 0;  
      
      button{
        width: 90%;
      }
    }
  }
}

@import "../../partials/variables";
@import "../../partials/fuctions";
@import "../../partials/mixins";

.Error{
    height: $mainPage-height;
    @include flex-center;
    flex-direction: column;

    h1{
        font-size: em(50px);
        color: $dmaps-orange;
    }
    a{
        // font-size: em(70px);
        font-size: 70px;
        color: $dmaps-orange;
        
        *:hover{
            box-shadow: 0 0 10px white;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
        }
    }
}
.BtnShowColorSet_PopUpColorSet{
    position: fixed;
    padding: 30px 0;
    overflow-y: auto; /* Přidá vertikální scrollování */
    width: 98%;
    height: 96vh;
    background-color: rgba(255, 255, 255, 0.95);
    left: 1%;
    top: 2vh;
    bottom: 2vh;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    // border-radius: 20px;
    z-index: 50;
    box-shadow: 0 0 10px black;
    

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); // For Safari

    .BtnShowColorSet_PopUpColorSet_main{
        position: relative;
        width: 100%;
        height: 100%;

        .BtnClose{
            width: 30px;
            height: 40px;
            right: 25px;
            // top: -10px;

            &:hover{
                color: rgb(221, 13, 13);
            }

            svg{
                width: 100%;
                height: 100%;
            }
        }

        .BtnShowColorSet_PopUpColorSet_main_response{
            display: flex;
            justify-content: center;
            width: 100%;
            // height: 96vh;
            // margin-top: 30px;
            
            padding-bottom: 20px;
        }
    }
}

.TagBox_active{
  top: -2%;
  left: calc(-2% + 14px);
  width: calc(104% - 28px);
  height: 104%;
  background-color: white;
}
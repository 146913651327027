.ErrorMsg {
  position: fixed; /* Vždy pevná pozice */
  top: 0; /* Pevné umístění na vrchol */
  left: 0;
  width: 100%;
  background-color: rgba(242, 93, 13, 0.8);
  color: white;
  padding: 10px;
  z-index: 1000;
  overflow: hidden;
  height: 0; /* Výchozí výška */
  transition: height 0.2s linear;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  font-size: 18px;

  &.visible {
    height: 40px; /* Výška při zobrazení */
    padding: 10px; /* Přidat padding */
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px); // For Safari
  }

  &.hidden {
    height: 0; /* Výška při skrytí */
    padding: 0; /* Odebrat padding */
  }
}

@import "../../../../partials/variables";

.containter-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin-top: 30px;

  .intput_box {
    position: relative;
    width: 100%;

    &:focus-within label {
      top: -15px;
      left: 20px;
      font-size: 15px;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"] {
      width: 100%;
      min-width: 250px;
      border-radius: 20px;
      padding: 8px 10px 7px 20px;
      outline: none;
      border: none;
      color: #0f0f0f;
      cursor: text;
      box-shadow: 0 0 0 0.15vw lighten($main-color_blue, 50%);
      font-size: 16px;
      background-color: $main-bgColor;

      &:hover {
        box-shadow: 0 0 0 0.15vw lighten($main-color_blue, 25%);
      }
      &:focus {
        box-shadow: 0 0 0 0.15vw $main-color_blue;
      }
    }

    label {
      cursor: text;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      color: #929191;
      background-color: transparent;
      padding: 0 5px;
      transition: all 0.2s ease-in-out;
    }
  }
}

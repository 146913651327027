.BtnSendEmail_NewRespondent{
    display: flex;
    gap: 10px;

    button{
        white-space: nowrap;
        padding: 10px;
        font-size: 16px;
        // #d1ebff
    }
}
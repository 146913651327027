@import "../../../../../../partials/variables";

.CompanyConsultans_List {
  width: 100%;
  border-top: 3px solid black;
//   height: 100%;

  h2 {
    padding-top: 15px;
    text-align: center;
    padding-bottom: 20px;
  }
  .consulants_list {
    display: flex;
    flex-direction: column;
    gap: 5px;

    // one Company Consultant
    .consulant {  
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: rgb(235, 235, 235);
      border-radius: 10px;
      padding: 5px 20px;
      // transition: transform 0.2s ease;
      gap: 10px;

      // &:hover {
      //   transform: scale(1.01);
      // }

      .companyConsultant_data {
        .name_box {
          display: flex;
          align-items: center;
          gap: 5px;
  
          .color {
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }
          .name {
            font-size: 20px;
            font-weight: bold;
          }
        }
        .contact {
          display: flex;
          flex-direction: column;
  
          .email_box,
          .phone_box{
              display: flex;
              gap: 5px;
  
              p{
                  white-space: nowrap;
              }
  
              .title{
                  color: $title-color;
                  padding-bottom: 0px;
                  width: auto;
              }
          }
        }
      }

      .companyConsultant_delete{
        .Btn_Delete{
          .btnDelete{
            font-size: 36px;
          }
        }
      }
      
    }
  }
}

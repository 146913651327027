@import "../../../../../partials/mixins";
@import "../../../../../partials/variables";

.Registration_window{
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: rgba(238, 238, 238, 0.5);
    backdrop-filter: blur(10px);
    
    -webkit-backdrop-filter: blur(10px); // For Safari

    display: flex;
    justify-content: center;
    align-items: center;
    
    @include logReg;
    
    .form{
        max-height: 90vh;
        background-color: rgb(248, 248, 248);
        overflow-y: auto;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h1{
            font-size: 32px;
        }

        .rank_select{
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }

        .box_colorSelect{
            padding-top: 20px;
            display: flex;
            justify-content: center;
            border-radius: 20px;
            position: relative;
    
            .btn_colorSelect{
                color: white;
                font-size: 18px;
                width: 100%;
                background-color: $dmaps-orange;
                padding: 10px;
                border-radius: 20px;
            }
        }
    }
    

    .parent{
        border-top: 2px solid $border-color;
        box-shadow: 0 0 20px black;
        margin-top: 25px;
        margin-bottom: 10px;
    }
}
@import "../../../../partials/variables";
@import "../../../../partials/mixins";

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $menu-color;
  min-height: $header-height;


  .nav-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: $header-height;

    .logo{
      height: $header-height;
      padding-left: 17px;
      @include flex-center;
      cursor: pointer;
      
      a{
        display: flex;
        gap: 20px;

        .logo_dmaps{
          height: 46px;
        }

        .text_dmaps{
          margin-top: 8px;
          height: 30px;
        }
      }

      *{
        cursor: pointer;
      }

      .novitim_logo_box{
        display: flex;
        // align-items: flex-end;
        // margin-bottom: 30px;
        gap: 15px;
        height: 100%;

        // p{
        //   color: $dmaps-orange;
        //   display: flex;
        //   align-items: center;
        //   padding-left: 10px;
        //   font-style: italic;
        // }

        .novitim_logo_box2{
          display: flex;
          align-items: center;
          padding-left: 10px;

          .novitim_logo{
            height: 25px;
          }
        }
      }
    }

    .humburger-button{
      display: none;

      *{
        color: $dmaps-orange;
      }
    }
  }

  .nav-box {
    display: flex;

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 30px;
      gap: 40px;

      a {
        // padding-left: 40px;
        text-decoration: none;
        transition: color 0.5s;
        font-size: 30px;
        // border: 1px solid red;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          color: $dmaps-lightBlue;
        }

        &.active {
          color: $dmaps-lightBlue;
        }

        .icon{
          margin-right: 5px;
        }
      }
    }
  }
}

.whiteBG{
  background-color: white;
}
/* Left Menu */

@media (max-width: $media-tablet) {
  header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .nav-header {
      width: 100%;
        display: flex;
        align-items: center;

        .humburger-button {
          display: block;
          background-color: transparent;
          border: none;
          margin: 0 18px;

          .hamburger-icon {
            color: $a-color;
            font-size: 40px;
          }
      }
    }

    .nav-box {
      width: 100%;

      nav {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        margin-right: 0px;
        gap: 0;

        a {
          width: 100%;
          margin-left: 0px;
          padding: 5px 0;
          color: white;

          &.active {
            color: lighten($main-color_blue, 40%);
          }
        }
      }

      .hide {
        height: 0px;
        overflow: hidden;
        transition: all 0.1s linear;
      }

      .show {
        height: 110px;
        transition: all 0.1s linear;
        // border-top: 1px solid rgb(121, 121, 121);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background-color: $main-color_blue;
      }
    }
  }
}

@import "../../partials/variables";
@import "../../partials/mixins";

.Login {
  @include flex-center;
  width: 100%;
  height: 100vh;
  background-color: $dmaps-darkBlue;
  overflow: hidden;
  @include logReg;

  .logo_rote{
    position: fixed;
    max-width: 250%;
    max-height: 250%;
    width: 700px;
    animation: rotate 20s linear infinite;
    overflow: visible;
    // z-index: -1;
    // pointer-events: none;
  }

  .form{
    position: relative;
    background-color: rgba(255, 255, 255, 0.9);
    // background-color: $dmaps-lightBlue;
    // z-index: 500;
    max-height: 90vh;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .button_box{
      button{
        border-color: $dmaps-darkBlue;
      }
    }
    

    .logo{
      position: absolute;
      width: 50px;
      height: 50px;
      right: 24px;
      top: 14px;
    }   
  }
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
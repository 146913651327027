@import "../../../../../../partials/variables";

.InputLine_Password_WithLabel{
    position: relative;

    button {
      position: absolute;
      right: 20px;
      top: 55%;
      transform: translateY(-50%);
  
      svg {
        width: 30px;
        height: 30px;
        color: $dmaps-darkBlue;
      }
    }
}
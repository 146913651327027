@import "../../../../../partials/variables";

.Btn_Switch_On_Another_Tag_PopUp{
    width: 100%;
    height: 460px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .name_box{
        .name{
            font-size: 30px;
            color: $dmaps-darkBlue;
        }
        .tag_name{
            color: $dmaps-orange;
            text-align: center;
        }
    }

    .tag_select{
        width: 90%;
    }
}
@import "../../partials/variables";
@import "../../partials/mixins";

.Ceo_Registration{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    .Ceo_Registration_Code{
        width: 310px;
    }
    .Ceo_Registration_Form{
        width: 80%;

        .company,
        .admin_status{
            text-align: center;
            font-weight: bold;
        }

        .company{
            font-size: 26px;
        }

        .admin_status{
            font-size: 22px;
            color: $dmaps-darkBlue;
        }

        
        .colorSelect_box{
            margin-top: 20px;
            padding-top: 20px;
            display: flex;
            justify-content: center;
            border-radius: 20px;
            position: relative;

            .btn_colorSelect{
                @include btn-main;
            }
        }
    }

    .btn_box{
        display: flex;
        justify-content: center;
        padding-top: 16px;
        padding-bottom: 5px;
        
    }

    .error{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 30px;
        color: red;
        margin-top: 8px;
        margin-bottom: 8px;
    }
}
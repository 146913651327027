.Consultants_New{
    background-color: rgb(202, 202, 202);
    width: 84%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-bottom: 20px;

    h2{
        text-align: center;
    }

    button{
        position: relative;
        width: 90%;

        svg{
            font-size: 36px;
            position: absolute;
            right: 20px;
        }
    }
}
.Password_Change{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h2{
        text-align: center;
    }

    p{
        color: red;
        text-align: center;
        min-height: 21px;
    }
}
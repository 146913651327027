@import "../../../../../partials/variables";
@import "../../../../../partials/mixins";

.OneCompany {
  @include popUp_full;

  .popUp_full_main {
    align-items: center;
  }

  .company_data {
    background-color: $dmaps-darkBlue;
    border-radius: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    .company_Name_box {
      padding-bottom: 20px;
      cursor: pointer;

      *{
        cursor: pointer;
      }

      .InputLook_Text{
        label{
          color: white;
        }

        .edit{
          input{
            text-align: center;
            font-weight: bold;
            font-size: 30px;
          }
        }

        .original_text{
          padding-top: 33px;
          font-size: 30px;
          color: white;
          font-weight: bold;
        }
      }
    }

    .company_edit_btn{
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        svg {
          font-size: 36px;
        }

        &:hover {
          scale: 1.05;
          background-color: lighten($dmaps-darkBlue, 10%);
      
          p {
            opacity: 0;
            visibility: hidden;
          }
      
          svg {
            transform: translateX(-120px) scale(1.2);
            left: 30px;
            transition: color 0.3s linear, transform 0.3s linear;
          }
        }
      }
    }

    .company_data_details1 {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      padding-top: 20px;

      .item {
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        border-radius: 10px;
        border: 5px solid $dmaps-lightBlue;

        .detail_title {
          color: $title-color;
        }
        .detail_text {
          font-size: 20px;
        }
      }

      .URL_box{
        .InputLook_Text{
          .label_button{
            p{
              color: $title-color;
            }
          }
          .intput_box{
            input{
              color: $dmaps-darkBlue;
              border-color: $dmaps-darkBlue;
            }
            label{
              display: none;
            }
          }
          .btn_edit{
            color: $dmaps-darkBlue;
            border-color: $dmaps-darkBlue;
            top: -26px;
          }
          .original_text{
            color: $dmaps-darkBlue;
            
            &:hover{
              border-color: black;
            }
          }
        }
      }
    }

    .company_data_CEO{
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      background-color: white;
      padding: 20px 0;
      border-radius: 10px;
    }
  }
}
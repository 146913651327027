.Consultants_Window {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    // gap: 10px;
    height: 460px;

    .btnShow_addConstulant{
        // justify-content: space-evenly;
        position: relative;
        width: 90%;

        svg{
            font-size: 36px;
            position: absolute;
            right: 20px;
        }
    }
}
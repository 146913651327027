@import "../../../../../../../partials/mixins";
@import "../../../../../../../partials/variables";

.Form_FeedBack_Consultant{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 5px;

    .box{
        display: flex;
        align-items: center;
    }

    .number {
        font-size: 26px;
        padding: 10px;
        color: $dmaps-darkBlue;
    }

    .code_box{
        @include code_box;
        padding: 10px 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        
        .code_data{
            cursor: pointer;
        }
        
    }

    .link_box {

        .code_title{
            padding-bottom: 10px;
        }

        .link {
          display: inline-block; /* Ujistí se, že <a> má blokové vlastnosti */
          padding: 10px 30px 5px 30px; 
          background-color: $dmaps-darkBlue;
          border-radius: 20px;
          color: $dmaps-orange;
          font-weight: bold;
          transition: all 0.3s linear;
          font-size: 40px;
      
          &:hover {
            background-color: $dmaps-orange;
            color: $dmaps-darkBlue;
          }
        }
    }

    .date{
        background-color: rgba(127, 255, 212, 0.2);
        border: 4px solid rgba(127, 255, 212, 0.2);
        border-radius: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0 5px 0;

        .Select_MUI_Date{
            background-color: white;
        }
    }

    .btn_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 4px;

        button{
            border: 3px solid $dmaps-orange;
            background-color: $dmaps-lightBlue;
            color: $dmaps-darkBlue;
            width: 90%;
            padding: 10px;
            border-radius: 20px;
            font-size: 20px;
            font-weight: bold;
            transition: all 0.3s linear;

            &:hover {
                color: $dmaps-orange;
                border-color: $dmaps-lightBlue;
                background-color: $dmaps-darkBlue;
            }
        }
    }
}
@import "./partials/variables";
@import "./partials/fuctions";
@import "./partials/mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default;

  font-family: -apple-system, BlinkMacSystemFont, "Reddit Mono", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scrollbar-color: orange white; /* Pro Firefox */
  scrollbar-width: auto; /* Standardní šířka pro Firefox */
}

/* Kompletní styl pro Webkit prohlížeče */
body::-webkit-scrollbar {
  width: 16px;
  height: 16px;
  background: white;
}

body::-webkit-scrollbar-thumb {
  background: orange;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background: darkorange;
}

body::-webkit-scrollbar-track {
  background: white;
}

/* Skryje šipky na posuvníku */
body::-webkit-scrollbar-button {
  -webkit-appearance: none; /* Skryje šipky */
  display: none; /* Záloha */
  height: 0;
  width: 0;
}

// SharedLayouts
  main {
    width: 100%;
    min-height: $mainPage-height;
  
    .UserDataSharedLayouts{
      display: flex;
      flex: 1;
      flex-grow: 1;
      min-height: $mainPage-height;
      
      .UserDataSharedLayouts_nav{
          width: $navDataUser-width;
          position: relative;
          flex-shrink: 0;
          
          nav{
              position: sticky;
              top: 0;
              width: $navDataUser-width;
              min-height: $navFormData-main-height;
              z-index: 10
          }
      }
      
  
      .UserDataSharedLayouts_main{
          flex: 1;
          flex-grow: 1;
          position: relative;
          // padding: 20px;
          
          .page{
            padding: 0px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: $page-height;
          }

          .title{
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
            width: 100%;

            h1 {
              border-bottom: 1px solid $main-color_blue;
              padding: 20px 0;
              width: 60%;
              text-align: center;
              color: $dmaps-darkBlue;
              // white-space: nowrap;
            }
          }
      }
    }
  }

// Elements
a,
button,
input[type="submit"] {
  text-decoration: none;
  cursor: pointer;
  color: black;
  border: none;
  background-color: transparent;

  * {
    cursor: pointer;
  }
}

.normalButton{
  // border: 2px solid $border-color;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 20px;
  background-color: white;
  transition: all 0.3s linear;
  color: $dmaps-darkBlue;
  border: 3px solid $dmaps-darkBlue;
  font-weight: bold;

  &:hover{
    background-color: $dmaps-darkBlue;
    color: white;
  }
}

.button {
  padding: 6px 9px 5px 9px;
  min-width: 140px;
  border-radius: 5px;
  font-size: 17px;
  transition: all 0.3s linear;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border: 1px solid white;
  color: black;

  &:disabled {
    box-shadow: 0 0 0 0.15vw $red-color;
    cursor: not-allowed;
  }
  &:enabled {
    box-shadow: 0 0 0 0.15vw $green-color;
    background-color: $green-color;
  }
  &:hover,
  &[type="submit"]:hover {
    border: 1px solid $blue-color;
  }

  * {
    transition: $transition;
  }
  &:hover * {
    color: $main-color_blue;
  }
}

.CheckBox {
  width: 180px;
  border-radius: 10px;
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    // cursor: pointer;
    flex-direction: column;
    padding: 10px 20px;
    text-align: center;
    height: 100%;
  }

  input[type="checkbox"] {
    display: none; /* Skryje původní checkbox */
  }

  .checkmark {
    width: 30px;
    height: 30px;
    background-color: #ccc;
    border-radius: 4px;
    position: relative;
    display: inline-block;
    transition: background-color 0.3s ease;
    // cursor: pointer;
  }

  /* Styl zaškrtnutého checkboxu */
  input[type="checkbox"]:checked + .checkmark {
    background-color: #4caf50; /* Barva zaškrtnutí */
  }

  /* Symbol uvnitř checkboxu */
  .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 3px;
    width: 9px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 2px 0;
    transform: rotate(45deg);
  }

  /* Zobrazení symbolu, když je checkbox zaškrtnutý */
  input[type="checkbox"]:checked + .checkmark::after {
    display: block;
  }
}






select {
  cursor: pointer;
}

.checkbox {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;

  label{
    cursor: pointer;
    font-size: 17px;
    white-space: nowrap;
  }

  /* Skrytí původního checkboxu */
  input[type="checkbox"] {
    -webkit-appearance: none; /* Webkit-based prohlížeče */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Obecně pro moderní prohlížeče */
    width: 20px;
    height: 20px;
    border: 2px solid #000; /* Černý okraj */
    border-radius: 4px; /* Kulaté rohy */
    background: #fff; /* Bílý pozadí */
    cursor: pointer; /* Změní kurzor na pointer při přejetí */
    position: relative; /* Pro pozicování pseudo-elementu */
  }

  /* Styl pro zaškrtnutý stav */
  input[type="checkbox"]:checked {
    background: #000; /* Černý pozadí */
  }

  /* Styl pro pseudo-element zaškrtnutí */
  input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 6px;
    width: 3px;
    height: 12px;
    border: solid #fff; /* Bílý zaškrtnutí */
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); /* Rotace zaškrtnutí */
  }
}
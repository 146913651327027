@import "./variables";

// @mixin page {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   min-height: $mainPage-height;
//   width: 100%;
// }

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin title {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;

  h1 {
    border-bottom: 1px solid $main-color_blue;
    padding: 20px 0;
    width: 60%;
    text-align: center;
    color: $dmaps-darkBlue;
    font-size: 20px;
    // white-space: nowrap;
  }
}

// ---------------------------
// BTN

@mixin classic-Button {
  border: 2px solid lighten($main-color_blue, 30%);
  border-radius: 15px;
  padding: 10px 20px;
  background-color: $main-color_blue;
  color: white;
  transition: all 0.3s linear;
  margin: 10px 0 20px 0;

  &:hover {
    color: $main-color_blue;
    background-color: white;
  }
}

@mixin btn-main {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  background-color: $dmaps-orange;
  transition: all 0.1s linear;
  color: white;
  font-size: 18px;

  &:hover{
      background-color: rgb(0, 138, 138);
  }
}
// ------------------------------

@mixin row {
  display: flex;
  padding-left: 10%;
  // border: 1px solid blue;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px $menu-color;
  background-color: rgb(241, 253, 255);

  .item {
    display: flex;
    flex: 1;
    // justify-content: flex-start;
    padding: 10px;

    .title_item {
      // display: flex;
      // justify-content: flex-start;
      width: min-content;
      padding-right: 10px;
      color: rgb(156, 156, 156);
      // font-size: 13px;
      // margin-top: 3px;
    }

    p {
      white-space: nowrap;
      // text-align: left;
      width: 100%;
    }
  }
}

// Button in left menu
@mixin button-full-div {
  .button-full-div {
    width: 100%;
    border: 2px solid rgb(1, 201, 184);
    padding: 8px;
    border-radius: 20px;
    background-color: white;
    transition: all 0.2s linear;
    color: black;
    font-size: 18px;

    &:hover {
      background-color: rgba(1, 201, 184, 0.384);
      border: 2px solid rgb(255, 255, 255);
    }
  }
}

// Pop Up Log/reg
@mixin logReg {
  .form {
    z-index: 1;
    border: 3px solid $dmaps-orange;
    min-width: 600px;
    max-width: 95%;
    // background-color: rgba(42, 42, 42, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 0 20px black;
    position: relative;
    background-color: white;

    .title {
      @include title;
    }

    .input_box {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 10px 0;
      width: 90%;
    }

    .error_box {
      padding: 10px 0 15px 0;
      p {
        font-size: 16px;
        min-height: 24px;
      }
    }

    .button_box {
      padding-bottom: 20px;

      button {
        @include btn-main;
        padding: 15px 20px;
        font-size: 22px;
      }
    }
  }
}

// POPUP - full page
@mixin popUp_full {
  $title_height: 65px;
  position: fixed;
  width: 98%;
  min-height: 96vh;
  background-color: rgba(255, 255, 255, 0.8);
  left: 1%;
  top: 2vh;
  border-radius: 20px;
  z-index: 50;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); // For Safari
  box-shadow: 0 0 10px black;

  .popUp_full_window {
    position: relative;
    width: 100%;
    height: 93vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .btnClose {
      position: absolute;
      width: 30px;
      height: 40px;
      right: 25px;
      top: -10px;
      transition: color 0.3s linear;

      &:hover {
        color: rgb(94, 94, 94);
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .popUp_full_title {
      height: $title_height;
      @include flex-center;

      h2 {
        text-align: center;
      }
    }

    .popUp_full_main {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

@mixin TagIntro {
  @include flex-center;

  .tag {
    width: 80%;

    .tag_box {
      border-radius: 20px;
      text-align: center;
      padding: 5px 10px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      flex-wrap: wrap;

      .tagInfo_box_label {
        color: rgb(136, 136, 136);
        padding: 0px 15px;
      }

      .tagInfo_box_admin {
        background-color: white;
        border-radius: 20px;
        padding: 10px;
        flex: 0.5;
        .tag_name {
          padding: 2px 7px;
          background-color: white;
          border-radius: 20px;
          white-space: nowrap;
        }
      }

      .tagInfo_box_tag {
        background-color: white;
        border-radius: 20px;
        padding: 10px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .tag {
          padding: 1px 8px;
          background-color: white;
          border-radius: 20px;
          font-size: 25px;
          white-space: nowrap;
        }
      }

      .tagInfo_box_company {
        background-color: white;
        border-radius: 20px;
        padding: 10px;
        flex: 0.5;
        .company {
          background-color: white;
          border-radius: 20px;
          padding: 2px 7px 5px 7px;
          font-size: 25px;

          white-space: nowrap;
        }
      }

      .tagInfo_box_dateCreate {
        background-color: white;
        border-radius: 20px;
        padding: 10px;
        flex: 0.5;
        .date {
          background-color: white;
          border-radius: 20px;
          padding: 2px 7px 3px 7px;
          // font-size: 25px;

          white-space: nowrap;
        }
      }
    }
  }
}

// fetch_data
@mixin fetch_data_one {
  padding-bottom: 5px;
  border-bottom: 2px solid rgb(158, 158, 158);
  padding: 10px;
  transition: all 0.2s linear;
  cursor: pointer;
  
  *{
    cursor: pointer;
  }

  &:hover {
    background-color: rgb(209, 209, 209);
  }

  .item {
    .one_title {
      color: rgb(88, 88, 88);
    }
    .one_name {
      font-size: 18px;
      padding-left: 20px;
    }
  }
}

@mixin code_box {
  width: 90%;
  background-color: rgba(127, 255, 212, 0.2);
  border: 4px solid rgba(127, 255, 212, 0.2);
  padding: 20px;
  border-radius: 30px;
  text-align: center;
              
  .code_title{
      font-size: 18px;
      color: rgb(94, 94, 94);
  }
  .tag_position{
    display: flex;
    justify-content: space-evenly;

    .position_text{
      font-size: 20px;
    }

  }
  .code_data{
      font-size: 24px;
  }
}

// lists
@mixin titles_line {
  margin-top: 10px;
  padding: 20px 5px;
  gap: 10px;
  display: flex;
  position: sticky;
  flex-wrap: wrap;
  top: 0px;
  // padding: 20px 0;
  // background-color: rgba(127, 255, 212, 0.2);
  background-color: rgba(0, 71, 119, 0.9);
  color: white;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); // For Safari
  border-radius: 10px;
  z-index: 1;
}
@mixin tag_box {
  border-radius: 20px;
  text-align: center;
  padding: 5px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transition: all 0.3s linear;
  max-width: 100%;
  flex-wrap: wrap;

  &:hover {
    transform: scale(1.1);
  }
  * {
    cursor: pointer;
  }

  .tag_data {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;

    .admin_name {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      background-color: white;
      border-radius: 20px;
    }
    .tag {
      padding: 5px 10px;
      background-color: white;
      border-radius: 20px;
      // color: $dmaps-orange;
      font-weight: bold;
      border: 2px solid white;
      width: fit-content;
      white-space: nowrap;
      overflow: hidden; 
      text-overflow: ellipsis;
    }
  }

  .job_position{
    width: 100%;
    background-color: white;
    color: $dmaps-darkBlue;
    border-radius: 20px;
    padding: 5px 10px;
    min-height: 30.91px;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    // text-align: left;
  }
}
@mixin name_email{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name {
      font-size: 20px;
      width: fit-content;
    }
    .email_btn {
      transition: all 0.3s linear;
      color: $title-color;
      width: fit-content;
      
      &:hover {
        transform: scale(1.1);
        padding-left: 5px;
      }
    }
    .email{
        position: absolute;
        background-color: rgb(214, 243, 255);
        padding: 5px;
        border-radius: 5px;
        padding-right: 35px;
        top: 50px;
        left: -5px;

        button{
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
          width: 20px;
          height: 20px;
        }
    }
}